import { ApolloError, isApolloError, ServerError } from '@apollo/client'
import { GraphQLError, GraphQLFormattedError } from 'graphql'

/**
 * Verify if user has a valid token
 */
export const isAuthenticationError = (
  error: GraphQLFormattedError,
): boolean => {
  return error.extensions?.['code'] === 'UNAUTHENTICATED'
}

/**
 * Verify if user has correct permissions
 */
export const isAuthorizationError = (error: GraphQLFormattedError) => {
  return error.extensions?.['code'] === 'UNAUTHORIZED'
}

const isServerError = (
  error: ApolloError['networkError'],
): error is ServerError => {
  return !!error && typeof error === 'object' && Object.hasOwn(error, 'result')
}

const extractNetworkErrors = (error: ApolloError) => {
  if (isServerError(error.networkError)) {
    const { result } = error.networkError

    if (typeof result === 'object') {
      const errors = result['errors'] as GraphQLError[]
      return errors || []
    }
  }

  return []
}

export const extractGraphqlErrors = (
  error: unknown,
): GraphQLFormattedError[] => {
  if (!(error instanceof Error) || !isApolloError(error)) return []

  return [...extractNetworkErrors(error), ...error.graphQLErrors]
}
